import React from "react"

import styles from './someBoks.module.css'

import linkedin from '../images/linkedin2.svg'
import facebook from '../images/facebook2.svg'
import instagram from '../images/instagram2.svg'
import github from '../images/github2.svg'



class SomeBoks extends React.Component {

  render () {
    let iconArray = {"linkedin":linkedin, "facebook":facebook, "instagram":instagram, "github":github}
    let urlArray = {"linkedin":"https://www.linkedin.com/in/andreasaarrestad/", "facebook":facebook, "instagram":"https://www.instagram.com/andreasaarrestad/", "github":"https://github.com/Andreasaarrestad"}

    return (
      <button className={"btn  " + styles.someKnapp}>
        <a href={urlArray[this.props.navn]} >
          <img src={iconArray[this.props.navn]} className="filter" style={{width:"25px"}}/>
        </a>
      </button>

    )
  }
}

export default SomeBoks
