import React from "react"


import styles from './boks.module.css'

const BoksHeader = (props) => (
	<>
		<div className={styles.headerDiv}>
			<h1 className={styles.header}>{props.headerName}</h1>
			<div className={styles.strek}></div>
		</div>
		<div className={styles.boksWrapper}>
			{props.children}
		</div>
	</>
)

export default BoksHeader
