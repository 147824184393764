import React from "react"
import Progressbar from '../components/progressbar'

import styles from '../components/progressBoks.module.css'

import security from '../images/ui.svg'
import code from '../images/code.svg'
import graphic from '../images/graphic.svg'
import webdesign from '../images/web-design.svg'

const progressBoks = (props) => {

	let dict = {"prog":["fas fa-code","#F1333F"],"sec":["fas fa-shield-alt","#F1333F"],"øko":["fas fa-chart-bar","#F1333F"],"design":["fas fa-layer-group","#F1333F"]};

	return (
	<>
		<div 
  		className="ferdighetCard" style={{padding:"50px 20px 50px 20px",marginTop:props.top}}>
			<h1 className="text-center" style={{fontSize:"60px",marginBottom:"30px"}}><i className={dict[props.bilde][0]}></i></h1>

			<div className="divCenter">
			<h3 style={{textAlign:"center",marginBottom:"10px"}} className="blockHeader">{props.header1 + ' og'} </h3>
			<h3 style={{textAlign:"center",marginBottom:"10px"}} className="blockHeader blockHeader2">{props.header2}</h3>
			</div>
			<Progressbar width={props.width} barcolor={dict[props.bilde][1]} title={props.title} />
			<div style={{marginTop:"20px"}}>
			<p>{props.children}</p>
			</div>
		</div>

	</>
	)
}

export default progressBoks
