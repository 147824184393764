import React from "react"
import styles from './boks.module.css'


const Boks = (props) => (
	<>
	{props.nav &&
		<div className={styles.div2} >
			{props.children}
		</div>
	}
	{!props.nav &&
		<div className={styles.div} >
			{props.children}
		</div>
	}
	</>
)

export default Boks
