import handleViewport from 'react-in-viewport';

import React, { useRef,useEffect } from 'react'


const Progressbar = (props) => {
  const { width,title,barcolor } = props;
  const Block = (props) => {
    const { inViewport,forwardedRef } = props;
      const width2 = inViewport ? width : "0%";
      const ref2 = useRef(null);
      const ref3 = useRef(null);
      const percentageMarginLeft = inViewport ? ((parseInt(width.replace("%", ""))/100*ref3.current.offsetWidth  - 0.4 * ref2.current.offsetWidth ) + "px") : "0%";
      return (
          <div ref={ref3}>
             <span ref={ref2}  className="progressPercentage" style={{ marginLeft: percentageMarginLeft,color:barcolor}}>{width}</span>
             <div ref={forwardedRef} className="progress" style={{marginTop:"5px"}}>
                <div className="progress-bar" role="progressbar" style={{width:width2,backgroundColor:barcolor}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
             </div>
          </div>
     );
  };

  const ViewportBlock = handleViewport(Block);
  return (
    <div>
      <ViewportBlock  />
    </div>
  )
}

export default Progressbar
