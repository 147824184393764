import handleViewport from 'react-in-viewport';
import React from 'react';

const TimelineLi = (props) => {
  const {children,header,year,desc } = props;
  const Block = (props) => {
    const { inViewport,forwardedRef } = props;
    const classView = inViewport ? "in-view" : "";

    return (
    		<li className={classView} ref={forwardedRef}>
    			<div style={{position:"relative"}}>
            <div style={{position:"absolute",bottom:-35,left:0,height:35,backgroundColor:"#db3d4c"}}>
              <p style={{marginTop:"-10px"}}><code className="timelineCode2">{year}</code></p>
            </div>

    				<time>{children}</time>{desc}

    			</div>
          <code className="timelineCode">{year}</code>
    		</li>
    );
  };

  const ViewportBlock = handleViewport(Block);
  return (

      <ViewportBlock  />

  )
}

export default TimelineLi
